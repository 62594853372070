/* eslint-disable react/jsx-wrap-multilines */
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
// import Home from 'components/Home/Home';
import Loading from 'components/Shared/Loading/Loading';
import PageNotFound from 'components/PageNotFound/PageNotFound';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'redux/language/selectors';
import { LANGUAGES } from 'constants/constants';

const ProcessPage = lazy(() => import('components/Process/ProcessPage'));
const Layout = lazy(() => import('components/Layout/Layout'));
const Process = lazy(() => import('components/Process/Process'));
const AboutUs = lazy(() => import('components/AboutUs/AboutUs'));
const Brand = lazy(() => import('components/Brand/Brand'));
const ContactUs = lazy(() => import('components/ContactUs/ContactUs'));
const Home = lazy(() => import('components/Home/Home'));

function App() {
  const lang = useSelector(selectLanguage);
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/:language" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/:language/process" element={<Process />} />
            <Route path="/:language/process/:id" element={<ProcessPage />} />
            <Route path="/:language/about-us" element={<AboutUs />} />
            <Route path="/:language/brands" element={<Brand />} />
            <Route path="/:language/contact-us" element={<ContactUs />} />
            <Route path="/:language/*" element={<PageNotFound />} />
          </Route>
          <Route path="/" element={<Navigate to={`/${lang || LANGUAGES.am}`} />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
